.rating-star {
    font-size: 2em;
    cursor: pointer;
    color: #ccc;
}

.rating-star:hover,
.rating-star.active {
    color: gold;
}

.bg-header {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: static;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.8);
}

.header {
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.typed {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    animation: typing;
    animation-duration: 4s;
    animation-timing-function: steps(30, end);
    animation-fill-mode: forwards;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

.fadeIn {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    -webkit-animation: fadeIn 1.5s forwards;
    -webkit-animation-delay: 1.5s;
    animation: fadeIn 1.5s forwards;
    animation-delay: 1.5s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.slide {
    overflow: hidden;
    position: absolute;
    left: -50vw;
    -webkit-animation: slide 3s forwards;
    -webkit-animation-delay: 4s;
    animation: slide 3s forwards;
    animation-delay: 4s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.btn:hover {
    transition: 2s linear;
    background: #4F95DA;
}
.logo {
    height: 80px;
}

.nav-link:hover {
    background-color: var(--bs-primary);
}

.navbar-scrolled {
    background-color: var(--bs-primary);
    box-shadow: 0 3px 10px rgb(4, 51, 157, 0.15)
}

.navbar {
    transition: all 1.0s;
}
.mapContainer {
    height: 500px;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}

.modalStyle {
  position: 'fixed';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  background-color: 'white';
  padding: '20px';
  z-index: 1000; 
  border: '1px solid #ccc';
  box-shadow: '0 4px 6px rgba(0, 0, 0, 0.1)';
  width: '80%';
  max-width: '600px';
};

.overlayStyle  {
  position: 'fixed';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'rgba(0, 0, 0, 0.5)';
  z-index: 999;
};